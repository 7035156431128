import React from "react";
import Header from "./components/Header";
import Cards from "./components/Cards";
import About from "./components/About";
import Footer from "./components/Footer";
import NewMenu from "./components/NewMenu";
import ScrollTop from "./components/ScrollTop";
import config from "./models/config.json";
import items from "./models/items.json";
import "./styles/app.css";
import { useState } from "react";
import disableBrowserBackButton from "disable-browser-back-navigation";

disableBrowserBackButton();
let siteLogo = config.general[0].logoURL;
let footerInfo = config.general[0];
let siteTitle = config.general[0].nombre

function App() {

  const [category, setCategory] = useState();
  const [showWidget, setShowWidget] = useState(false);
  const [selectCard, setSelectCard] = useState();


  const myGallery = selectCard ?
   window.cloudinary.galleryWidget({
    container: "#my-gallery",
    cloudName: "cfacilito",
    mediaAssets: [{ tag: selectCard["tag"],
    transformation: { crop: "fill" } }],
    imageBreakpoint: 5,
    aspectRatio: "16:9"
  }) : false

  const renderize = (tag, key) => {

    if (key) {
      if (myGallery) myGallery.render()
    }
    else {
      if (myGallery) myGallery.destroy()
     }
  }

  if (selectCard) { myGallery.render() }
  else {  if (myGallery) myGallery.destroy() }

  let styles = {
    app: {
    },
    menuTitle: {
      display: category ? "block" : "none",
      textAlign: "center",
    },
    widgetWrapper: {
      display: showWidget ? "flex" : "none",
      width: "100%",
      position: "fixed",
      justifyContent: "center",
      textAlign: "center",
      maxWidth: "400px",
      height: "100vh",
      flexDirection: "column",
      WebkitBackdropFilter: "blur(5px) contrast(70%)",
      backdropFilter: "blur(5px) contrast(70%)",
      zIndex: "12",
    },
    wrapper: {
      margin: "0 auto",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      zIndex: 20,
    },
    info: {
    },
  }

  const toggleScroll = (value) => document.getElementById("body").style.overflow = value ? "auto" : "hidden"


  return (
    <div className='App' >
      <div style={styles.widgetWrapper}>
        <div style={styles.wrapper}>
          <div id="my-gallery">
          </div>
        </div >
        <div style={styles.info}>
          <NewMenu selectCard={selectCard} setSelectCard={() => {
            renderize(selectCard["tag"], false)
            setSelectCard();
            toggleScroll(true);
            setShowWidget(false)
          }} />
        </div>
      </div>
      <Header logo={siteLogo} title={siteTitle} />
      <About uptCategory={(cat) => {
        setCategory(cat)
        setShowWidget(false)
      }} />
      <div id="menu"></div>
      <div style={styles.cards}  >
        <h2 style={styles.menuTitle}>{category}</h2>
        <Cards category={category} selectCard={(idx) => {
          setSelectCard(items[category][idx]);
          setShowWidget(true);
          toggleScroll(false);
          renderize(items[category][idx]["tag"], true)
        }} />
      </div>
      <Footer info={footerInfo} />
      <ScrollTop />
    </div>
  );
}

export default App;
