import React from "react";
import { useState } from "react";
import "../styles/scroll.css";

function ScrollTop() {

  const [position, setPosition] = useState(0)
  window.onscroll = () =>setPosition(window.scrollY)

  return (
    <div className='scroll' onClick={() => window.scrollTo(0, 0)} style={{display: position > 30 ? "flex" : "none"}}>
    {/* <div className='scroll' id='scroll' onClick={() => scrollTop()}> */}
      <b>&#8593;</b>
    </div>
  );
}

export default ScrollTop;
