import React from "react";
import { useState } from "react";
import "../styles/header.css";

function Header({ logo, title }) {
  function Links() {
    const [showSideNav, setShowSideNav] = useState("hidde")
    const styles = {
      hidde: {
        width: 0,
        opacity: 0
      },
      show: {
        width: "200px",
        opacity: 1,
      },
    }

    return (
      <div id={'navIcon'}>
        <span style={{ fontSize: "2.5rem", cursor: "pointer" }} onClick={() => setShowSideNav("show")}>
          &#9776;
        </span>
        <div id='sideNav' style={styles[showSideNav] }>
          <span
            id='closeBtn'
            onClick={() => setShowSideNav("hidde")}
            style={{ marginTop: "2rem", cursor: "pointer", fontSize: "3rem", color: "ghostwhite"  }}>
            &#10799;
          </span>
          <br />
          <a href='#about' onClick={() => setShowSideNav("hidde")} >Conócenos</a>
          <a href='#menu' onClick={() => setShowSideNav("hidde")} >Menú</a>
          <a href='#links' onClick={() => setShowSideNav("hidde")} >Enlaces</a>
          <a href='http://wa.me/573007833611' target="_blank" rel="noopener noreferrer" onClick={() => setShowSideNav("hidde")} >Escríbenos</a>
        </div>
      </div>
    );
  }

  return (
    <div className='navBar'>
      <header>
        <Links id='menu' />
        <h1>{title}</h1>
        <div id='siteLogoContainer'>
          <img alt='site logo' src={logo} />
        </div>
      </header>
    </div>
  );
}

export default Header;
