import React from "react";
import digest from "../digest/digest";
import "../styles/about.css";
import config from "../models/config.json";
let info = config.bienvenida[0];

function About({ uptCategory }) {
  return (
    <div className='about' id='about'>
      <div id='imgContainer'>
        <img alt='food' width="100%" src={`${digest.url}w_400/CoppiWeb/${info.imagen}`} />
      </div>
      <div id="sectionContainer">
        <section>
          <h2>{info.titulo}</h2>
          <div className='vegetables'>
            <span role='img' aria-label=''>
              &#127846;&#127846;&#127846;
            </span>
          </div>
          <img width="100%" height="100%" src={"https://res.cloudinary.com/cfacilito/image/upload/v1602980122/WhatsApp_Image_2020-10-17_at_7.14.03_PM_lchgpt.jpg"} alt="food"></img>
          <h2 id="menu">Nuestro menú</h2>
          {/* <p>{info.descripcion}</p> */}
          <br />
        </section>
        <section id='miniCardsContainer'>
          <a href="#menu" onClick={() => uptCategory("HELADOS")} >
            <div className="menuWrapper">
              <h3>{info.subtitulo1}</h3>
            </div>
            <img className="aboutImgs" src={`${digest.url}w_200/CoppiWeb/${info.icono1}`} alt="food"></img>
          </a>
          <a href="#menu" onClick={() => uptCategory("PANADERIA")}>
            <div className="menuWrapper">
              <h3>{info.subtitulo2}</h3>
            </div>
            <img className="aboutImgs" src={`${digest.url}w_200/CoppiWeb/${info.icono2}`} alt="food"></img>
          </a>
          <a href="#menu" onClick={() => uptCategory("REPOSTERIA")}>
            <div className="menuWrapper">
              <h3>{info.subtitulo3}</h3>
            </div>
            <img className="aboutImgs" src={`${digest.url}w_200/CoppiWeb/${info.icono3}`} alt="food"></img>
          </a>
          <a href="#menu" onClick={() => uptCategory("BEBIDAS")}>
            <div className="menuWrapper">
              <h3>{info.subtitulo4}</h3>
            </div>
            <img className="aboutImgs" src={`${digest.url}w_200/CoppiWeb/${info.icono4}`} alt="food"></img>
          </a>
        </section>
      </div>
    </div>
  );
}

export default About;
