let digest = {
  url: "https://res.cloudinary.com/cfacilito/image/upload/",
    showCurrency: (string) => {
        if (parseInt(string)) {
          let price = string.split("");
          if (price.length > 3) {
            price.splice(-3, 0, ".");
            price = price.join("");
            return `$ ${price}`;
          }
          return price;
        }
        return string
      },
      randomColor: () => {
        let rn = () => (Math.random() * (255)).toFixed()
        let color = `rgb(${rn()},${rn()},${rn()}, 0.3)`
        return color
      },
}

export default digest