import React from "react"
import digest from "../digest/digest"

const NewMenu = ({ selectCard, setSelectCard }) => {

    let styles = {
        title: {
            width: "100%",
            textAlign: "center",
            margin: "10px 0 5px 0",
            backgroundColor: "white",
            padding: "0.5rem",

        },
        btn: {
            fontSize: "1.5rem",
            width: "50%",
            backgroundColor: "teal",
            color: "white",
            padding: "1rem",
            borderRadius: "5px",
            border: "none",
            boxShadow: "0 2px -2px 5px rgb(254, 254, 254, 0.2)",
            margin: "0.5rem auto 0",
        },
        infoWrapper: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-evenly",
            margin: "0.5rem auto 0",
        },
        options: {
            fontFamily: "roboto",
            backgroundColor: "white",
            padding: "0.5rem",
            borderRadius: "3px",
            margin: "0.25rem",
            textAlign: "center",
            userSelect: "none",
        },

    };
    let options = selectCard ? selectCard.precio.toString().split(",") : []
    return (
        <div style={styles.body}>
            <div style={styles.widgetWrapper}>
                <h2 style={styles.title}>{selectCard ? selectCard.titulo : ""}</h2>
                <div style={styles.infoWrapper}>
                    {options.map((option, idx) => (
                        <p style={styles.options} key={idx}>{selectCard.presentacion.split(",")[idx]}<br></br> {digest.showCurrency(option)}</p>
                    ))}
                </div>
                {/* <h2 id='price'>{digest.showCurrency(price)}</h2> */}
                <button style={styles.btn} onClick={() => {
                    setSelectCard()
                }}>Volver</button>
            </div>
        </div>
    )
}

export default NewMenu