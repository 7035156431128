import React from "react";
import "../styles/footer.css";

function Footer({ info }) {
  return (
    <div className='footer'>
      <h2>{info.nombre}</h2>
      <p id='text'>{info.slogan}</p>
      <section className='contact'>
        <div>
          <a href='http://wa.me/573174096418' target="_blank" rel="noopener noreferrer">
            <i className='fa fa-phone fa-lg'></i>
            <p>{info.telefonos}</p>
          </a>
          <a href='mailto:' target="_blank" rel="noopener noreferrer">
            <i className='fa fa-envelope fa-lg'></i>
            <p>
              {info.email}</p>
          </a>
          <a href='https://g.page/coppiheladoartesanal?share' target="_blank" rel="noopener noreferrer">
            <i className='fa fa-street-view fa-lg'></i>
            <p>{info.direccion}</p>
          </a>
        </div>
      </section>

      <section id='socialLinks'>
        <a href={info.facebook} target="_blank" rel="noopener noreferrer" className='fa fa-facebook'>
          {" "}
        </a>
        <a href={info.instagram} target="_blank" rel="noopener noreferrer" className='fa fa-instagram'>
          {" "}
        </a>
        {/* <a href={info.twitter} target="_blank" rel="noopener noreferrer" className='fa fa-twitter'>
          {" "}
        </a>
        <a href={info.pinterest} target="_blank" rel="noopener noreferrer" className='fa fa-pinterest'>
          {" "}
        </a>
        <a href={info.youtube} target="_blank" rel="noopener noreferrer" className='fa fa-youtube'>
          {" "}
        </a> */}
      </section>

      <section id='links'>
        <a href={info.enlace1URL}>{info.enlace1titulo}</a>
        <a href={info.enlace2URL}>{info.enlace2titulo}</a>
        <a href={info.enlace3URL}>{info.enlace3titulo}</a>
      </section>
      <section>
        <span>
          <a href="http://compufacilito.com" target="_blank" rel="noopener noreferrer">Sitio creado por compuFacilito</a>
          <a style={{ fontFamily: 'arial', fontSize: "0.5rem", padding: "0.5rem" }} rel="license noopener noreferrer" target="_blank" href="http://creativecommons.org/licenses/by-nc/4.0/">CC BY-NC 4.0</a>
        </span>
      </section>
    </div>
  );
}

export default Footer;
